<template>
  <v-sheet class="transparent page-sheet">
    <v-row justify="end">
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" clearable></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      item-key="idx"
      sort-by="created"
      class="transparent table-custom"
      show-expand
      :footer-props="foot_props"
      :no-data-text="$t('label.nodata')"
    >
      <template #item.price="{item}">{{item.price|currency}}</template>
      <template #expanded-item="{item}">
        <td :colspan="headers.length">{{item.body}}</td>
        <td>
          <v-btn
            icon
            color="error"
            :disabled="item.status=='created'"
            @click.prevent="deleteTicket(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            icon
            color="success"
            :disabled="item.status!='created'"
            @click.prevent="completeTicket(item)"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import commonmixin from "@/mixins/commonlist.js";
const apiBase = "v1/cms/tickets";

export default {
  name: "Tickets",
  mixins: [commonmixin],
  computed: {},
  data() {
    return {
      item: {},
      d_edit: false,
      headers: [
        {
          text: this.$t("fields.ticket"),
          value: "idx",
        },
        {
          text: this.$t("fields.created"),
          value: "created",
        },
        {
          text: this.$t("fields.email"),
          value: "emailto",
        },
        {
          text: this.$t("fields.subject"),
          value: "subject",
        },
        // {
        //   text: this.$t("fields.body"),
        //   value: "body",
        // },
        {
          text: this.$t("fields.status"),
          value: "status",
        },
      ],
    };
  },
  methods: {
    loadData() {
      this.$api.apiGetRequest(apiBase).then((response) => {
        this.items = [...response];
      });
    },
    deleteTicket(i) {
      this.$api.apiDeleteRequest(apiBase + "/" + i.idx).then((response) => {
        if (response) {
          const ind = this.items.findIndex((e) => e.idx == i.idx);
          if (ind != -1) this.items.splice(ind, 1);
        }
      });
    },
    completeTicket(i) {
      i.status = 'completed';
      this.$api.apiPostRequest(apiBase, i).then((response) => {
        if (response.idx) {
          const ind = this.items.findIndex((e) => e.idx == i.idx);
          if (ind != -1) this.items.splice(ind, 1, response);
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style>
</style>